<template>
    <component :is="tagNm||'span'" class="fileSelect">
        <template v-if="type.toUpperCase() == 'IMAGE'">
            <template v-if="!disabled">
				<div class="imgDelBtn" v-if="imgYn">
					<!-- <img src="/images/close_btn_icon.png" onclick="window.confirm('기본이미지로 변경하시겠습니까?')" /> -->
					<img src="/images/close_btn_icon.png" @click="removeImg()" />
				</div>
				<div class="img"><ImageComp alt="기본사진" :src="fileSrc" :defaultSrc="defaultSrc" @click="$emit('click', $event)" @imgYn="atchImgYn" ref="profileImg" /></div>
                <span class="ctm" v-if="name">{{name}}</span>
                <div class="img-attach" @click="addFile">+ 이미지 첨부</div>
            </template>
            <template v-else>
				<div class="img"><ImageComp alt="기본사진" :src="fileSrc" :defaultSrc="defaultSrc" @click="$emit('click', $event)"/></div>
                <span class="ctm" v-if="name">{{name}}</span>
                <!--div class="img-attach">+ 이미지 첨부</div-->
            </template>
        </template>
        <template v-if="type.toUpperCase() != 'IMAGE'" >
            <input :class="classNm" type="text" :value="filename" readonly placeholder="파일선택" @click="$emit('click', $event)"/>&nbsp;
			<div v-if="type.toUpperCase() == 'ADMIN'" class="find_btn" @click="addFile" :disabled="disabled">찾아보기...</div>
            <img v-else  class="file_search" :class="classNm" src="/images/tec/prf/file_search.png" alt="파일찾기" @click="addFile" :disabled="disabled"/>
        </template>
        <input type="file" ref="selectFile" @change="changeFile" @click="open" :accept="fileAccept" style="display:none;"/>
		<errorComp ref="error" :value="value" :title="title" :showError="showError" :style="{top:(this.$el&&this.$el.clientHeight||'0')+'px'}"/>
		
        <!--span class="error" v-if="error && showError" :style="{top: ($el.offsetHeight + 1) + 'px'}">{{error.message}}</span-->
    </component>
</template>

<script>
/**
 * 파일 컴포넌트
 *      -> 파일선택 후 선택한 파일정보를 보여주고 파일객체를 emit 으로 전송
 * 
 * 사용법)
 * @props value     : File객체
 * @props type      : IMAGE, FILE 구분
 * @props width     : IMAGE 타입의 경우 width 지정
 * @props height    : IMAGE 타입의 경우 height 지정
 * @props name      : FILE 타입의 경우 파일명
 * @props src       : 다운로드 경로
 * @props defaultSrc: IMAGE 타입의 경우 디폴트 이미지 경로
 * @props accept    : 선택 가능 파일
 * @props disabled  : disabled 여부 (Y, N)
 * 
 * @emit  input : File객체
 * 
 * @예시
 *  <file-select type="file" v-model="fileModel" :src="fileURL" :fileName="fileName" disabled="Y"/>
 *  <file-select type="image" v-model="imageModel" :src="imageURL" defaultSrc="/res/img/default.png"/>
 */
import PronError from '/src/assets/js/PronError'
import errorComp from "@/components/ErrorComp.vue";
export default {
	components:{errorComp},
    props: {
		title: String,
        value: {},
		type: String,
        width: Number,
        height: Number,
		name: String,
		src: String,
        defaultSrc: String,
		accept: String,
        tagNm: String,
        classNm: String,
        disabled: {},

		showError : { type: Boolean, default : true },
		rules : { },
    },
    data() {
        var accept = {
            'IMAGE': 'image/*'
        }
		var retData = {
            fileData : false,
            fileAccept: this.accept || accept[this.type.toUpperCase()] || '',
            val : null,
			imgYn : false
        }
		
		return retData;
    },
	watch: {
		value(){
            //console.debug('FileSelect', 'watch value', this.value)
			if(this.value instanceof Error){
				//console.debug('FileSelect', 'watch', this.value.message)
			} else if(this.val != this.value){
				this.val = this.value;
				this.validate();
			}
		},
		rules(){
            //console.debug('FileSelect', 'watch rules', this.rules)
			if(this.validate()){
				this.emitInput(this.val);
			}
		},
		src(){
            //console.debug('FileSelect', 'watch rules', this.rules)
			if(this.value instanceof Error && this.validate()){
				this.emitInput(this.val);
			}
		},
		name(){
            //console.debug('FileSelect', 'watch rules', this.rules)
			if(this.value instanceof Error && this.validate()){
				this.emitInput(this.val);
			}
		}
	},
    mounted(){
        this.validate();
		// console.log('filelelel', this)
    },
    methods : {
        addFile() {
            if(!this.disabled){
                this.$refs.selectFile.click();
            }
        },
        open(event){
            if(!this.value){
                event.target.value = '';
            }
        },
        changeFile(event) {
            var files = event.target.files;
            //console.debug('FileSelect', 'changeFile', files)
            if(files && files[0]) {
				
				if(this.type.toUpperCase() == 'IMAGE'){
					var reader = new FileReader();
					reader.onload = (e) => {
						this.fileData = e.target.result;
					}
					reader.readAsDataURL(files[0]);
				}
                this.val = files[0];
                if(this.validate() === true){
                    this.emitInput(files[0]);
                    this.$emit('change', event, files[0]);
                }
            }
        },
        emitInput(val){
            if(val instanceof Error){
                if(this.value instanceof Error && this.value.message == val.message){
                    //console.debug('FileSelect', 'emitInput', val.message);
                } else {
                    if(!val.details) val.details = this.$el;
					this.$emit('input', val);
                }
            } else {
                this.$emit('input', val);
            }
        },
		validate(){
			var result = true;
			if(this.rules){
				switch(typeof this.rules){
					case 'object' :
						for(var key in this.rules){
							var obj  = this.rules[key];
							var rule = undefined;
							var args = undefined;
							var msg  = undefined;
							if(typeof key == 'string') rule = key;
							if(typeof obj == 'object'){
								if(obj.rule !== undefined) rule = obj.rule;
								if(obj.args !== undefined) args = obj.args;
								if(obj.msg  !== undefined) msg  = obj.msg;
							} else {
								args = this.rules[key];
							}
							
							result = this.ruleCheck(rule, args, msg);
							if(result !== true) return false;
						}
						break;
					case 'string' :
						var rules = this.rules.split(',');
						for(var i in rules){
							var sp = rules[i].split(':');
							result = this.ruleCheck(sp[0], sp.slice(1));
							if(result !== true) return false;
						}
						break;
					case 'function':
						result = this.ruleCheck(this.rules);
						if(result !== true) return false;
				}
			}

			return result;
		},
		ruleCheck(rule, args, msg){
			if(this.val instanceof Error) return false;
			var v = this.val || '';
			var title = '[' + (this.title ? this.title: v) + '] ' ;
			var result = true;
			if(typeof args == 'function'){
				result = args(v, msg)
			} else if(typeof rule == 'function'){
				result = rule(v, args, msg)
			} else if(!v && !this.src && !this.name) {
				args = Array.isArray(args) ? args : [ args ];
				if(rule == 'required' && args[0] !== false && args[0] !== 'false') result = new PronError('IRREQD', msg || (title + '필수 입력사항 입니다.'));
			} else if(rule != 'required') {
				args = Array.isArray(args) ? args : [ args ];
				var baseRules = {}

				if(baseRules[rule]) result = baseRules[rule]();
				else result = new PronError('IRNONE', title + '의 룰을 확인 할 수 없습니다.(' + rule + ')');
			}

			
			if(result !== true){
				var errCode = 'IRERR'
				/*if(result instanceof Error) console.debug('InputComp', 'ruleCheck', rule, v, result.code, result.message);
				else*/ if(typeof result == 'string') result = new PronError(errCode, result);
				else result = new PronError(errCode, title + '입력 룰 검증에 실패했습니다.');

				this.emitInput(result);
				return result;
			}
			return true;
		},
		atchImgYn(imgYn) {
			this.imgYn = imgYn;
		},
		removeImg() {
			if(window.confirm('기본이미지로 변경하시겠습니까?')) {
				this.$refs.profileImg.deleteImg();
			}

		}
    },
	computed : {
        filename(){
            if(this.value && this.value instanceof File)
                return this.value.name;
            return this.name || '';
        },
        fileSrc(){
			if(this.fileData) {
				// console.log("fileSrc", "fileData", this.fileData);
				return this.fileData;
			}

			// var res = this.src || this.defaultSrc || ''
			// console.log("fileSrc", "res", res);
			
            return this.src || this.defaultSrc || ''
        },
		error(){
			if(this.value instanceof Error)
				return this.value;
			else 
				return false;
		}
    }
}
</script>
<style scoped>
.fileSelect {
	position: relative;
}

.imgDelBtn {
	position: absolute;
	margin-top: 2px;
	margin-left: 155px;
}

.imgDelBtn img{
	cursor: pointer;
}
</style>