<template>
    <div class="resume-box">
        <div class="tit">{{title}}
            <slot name="desc" v-if="writer">
                <template v-if="desc"><br/><span>{{desc}}</span></template>
            </slot>
        </div>
        <slot name="top"></slot>
        <template v-for="(item, idx) in value">
            <div class="info" :class="{plus : ['career', 'project'].indexOf(type) >= 0||item.admCommentCont, career: admin && ['career', 'project'].indexOf(type) >= 0}" :key="'info_' + getKey(item)" ref="item">
                <template v-if="admin">
                    <div class="career-date" v-if="months">▼경력 {{ item[months] | months}}</div>
                    <template v-if="type == 'career'">
                        <div class="alert cp" v-if="item.nhisOcrRecogResultYn == 'Y'">【건강보험 OCR 인식 완료 】</div>
                        <div class="alert" v-else>【※ 건강보험 OCR 인식 확인필요 】</div>
                    </template>
                    <div class="mark" v-if="['career', 'project', 'edu', 'school'].indexOf(type) >= 0">
                        <div class="kosa" v-if="item.kosaScrapStatusCd == '01'">KOSA인증</div>
                        <!-- <div class="confirmed" v-if="item.admCfmDate">확정</div> -->
                        <div class="confirmed" v-if="item.admCfmStatusCd == '03'">확정</div>
                        <div class="notconfirmed" v-else>등록</div>
                        <div class="update-time" v-if="!months">
                            UPDATE / 
                            <span>{{item.mberModDate | date('yyyy.MM.dd HH:mm')}}</span>
                        </div>
                    </div>
                    <div class="update-time" v-if="months">
                        UPDATE / 
                        <span>{{item.mberModDate | date('yyyy.MM.dd HH:mm')}}</span>
                    </div>
                </template>
                <slot :item="item" :index="idx" />
            </div>
            <div class="notice-frame" v-if="(item.admCommentCont || item.edit) && (writer || admin)" :key="'notice_' + getKey(item)">
                <img v-if="item.admCfmStatusCd != '02'" src="/images/tec/prf/notice_frame.png" alt="정보수정알림"/>
                <img v-else src="/images/admin/notice_frame_02.png" alt="정보수정알림"/>
                <template v-if="item.edit">
                    <textarea v-model="item.edit.admCommentCont" :placeholder="'정보수정바랍니다\n수정 요청하실 내용을 입력해 주세요!'"></textarea>
                    <div class="btn">
                        <img src="/images/admin/alert_btn01.png" alt="확인" @click="saveComment(idx)"/>
                        <img src="/images/admin/alert_btn02.png" alt="취소" @click="cancelComment(idx)"/>
                    </div>
                </template>
                <p v-else>
                    <span class="tit">정보수정바랍니다!</span><br/>
                    {{item.admCommentCont}}
                </p>
                <img class="close" src="/images/close.png" alt="닫기" v-if="admin" @click="deleteComment(idx)"/>
            </div>
        </template>
        <div v-if="admin && (!this.value || this.value.length == 0)" class="info">등록 된 내용이 없습니다.</div>
        <div class="plus-btn" v-if="writer" @click="add()"><img src="/images/tec/prf/plus_btn.png" alt="추가버튼"/></div>
    </div>
</template>

<script>
export default {
    props : { title:String, type:String, value:Array, mberSeq:Number, desc:String, months:String },
    data() { return { uniqNum : 0}; }, 
	watch: {
		value(){
            //console.log('resumebox', 'watch', this.title, JSON.stringify(this.value))
            if(this.writer){
                if(!this.value) this.$emit('input', [{}]);
                else if(this.value.length == 0) this.add();
            }
		}
	},
    mounted(){
        //console.log('resumebox', 'mounted', this.title, JSON.stringify(this.value))
        if(!this.value) this.$emit('input', [{}]);
        else if(this.value.length == 0) this.add();
    },
    methods: {
        add(data){
            //console.debug('ResumeListBox', 'add', data);
            if(this.writer){
                var item = {};
                var isNew = true;
                if(typeof data == 'object' && data){
                    if(data.kosaIdenti){
                        //data에 코사식별자가 있으면 해당 코사 식별자가 같은 항목을 업데이트 한다.
                        for(var i in this.value){
                            if(this.value[i].kosaIdenti == data.kosaIdenti){
                                item = this.value[i];
                                isNew = false;
                            }
                        }
                    }
                    
                    if(isNew){
                        //신규일 경우 가장 마지막 아이템이 비었는지를 체크한다.
                        //마지막 아이템이 비었으면 아이템을 추가하지 않고 마지막 아이템을 return 한다.
                        var last = this.value[this.value.length - 1];
                        var isNotEmpty = false;
                        for(var k1 in last){
                            if(k1.indexOf('_') != 0 && last[k1]){
                                isNotEmpty = true;
                                break;
                            }
                        }
                        if(isNotEmpty == false){
                            item = last;
                            isNew = false;
                        }
                    }

                    //아이템에 데이터를 복사한다.
                    for(var k2 in data){
                        item[k2] = data[k2];
                    }
                }

                //신규 일 경우 list에 추가한다.
                if(isNew) this.value.push(item);

                this.$emit('add', item);
                this.$forceUpdate();

                return item;
            }
        },
        comment(index){
            var item = this.value[index];
            this.$set(item, 'edit', Object.assign({}, item));
        },
        saveComment(index){
            var item = this.value[index];
            if(this.admin && item && item.edit){
                if(!item.edit.admCommentCont.trim()){
                    return alert('수정요청 내용이 없습니다. 내용을 입력 후 저장해주시기 바랍니다.');
                } else if(confirm('수정요청 내용을 저장하시겠습니까?')){
                    var param = Object.assign({}, item.edit);
                    param.tecMberSeq = this.mberSeq;
                    this.$.http('/api/mem/adm/prf/comment', param)
                        .then(() => {
                            item.admCfmStatusCd = '01';
                            item.admCommentCont = item.edit.admCommentCont;
                            this.cancelComment(index);
                        }).catch(this.$.httpErrorCommon);
                }
            }
        },
        cancelComment(index){
            var item = this.value[index];
            // console.log('cancelComment', item);
            if(this.admin && item && item.edit){
                this.$set(item, 'edit', null);
            }
        },
        deleteComment(index){
            var item = this.value[index];
            if(this.admin && item && item.admCommentCont){
                if(confirm('수정요청 내용을 삭제하시겠습니까?')){
                    this.cancelComment(index);
                    var param = Object.assign({}, item);
                    param.admCommentCont = '';
                    param.tecMberSeq = this.mberSeq;
                    this.$.http('/api/mem/adm/prf/comment', param)
                        .then(() => {
                            item.admCfmStatusCd = '01';
                            item.admCommentCont = ''
                         }).catch(this.$.httpErrorCommon);
                }
            }
            this.cancelComment(index);
        },
        remove(index){
            if(this.writer){
                var de = this.value.splice(index, 1);
                de[0].mberSeq = this.mberSeq;
                
                // console.log(this.$parent.data.del);
                if(this.$parent.data.del == undefined) {
                    this.$parent.data.del = de;
                    this.$parent.data.del[0].tecType = this.type;
                } else {
                    de[0].tecType = this.type;
                    this.$parent.data.del[this.$parent.data.del.length] = de[0];
                }
                // console.log(this.$parent.data);
                //if(this.value.length == 0){
                //    this.add();
                //}
            }
        },
        getKey(item){
            if(!item._key) item._key = 'K'+ (this.uniqNum ++);
            return item._key;
        },
        focusOn(index){
            var refs = this.$refs.item;
            // console.log('focusOn', refs, index);
            if(index >= 0 && index < refs.length){
                this.$.focus(refs[index]);
            } else {
                this.$.focus(this);
            }
        },
        checkAdminComment(){
            var count = 0;
            for(var i in this.value){
                if(this.value[i].edit){
                    return false;
                } else if(this.value[i].admCommentCont && this.value[i].admCfmStatusCd == '01'){
					count ++;
                }
            }
            return count;
        }
    },
    computed:{
        writer(){ return this.mberSeq == this.$store.state.userInfo.mberSeq; },
        admin() { return this.$store.getters.isAdmin },
    }
};
</script>